import { Flex, Heading, Link, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import { Logo } from '@src/components';
import { AuthContainer, Login } from '@src/components/Auth';
import {
  AnalyticsEvent,
  AuthMethod,
  useAnalytics,
  useApprovedToRedirect,
  useAuthMethod,
  useRedirectOnAuthentication,
  RedirectKeys,
} from '@src/hooks';
import { GetServerSideProps, NextPage } from 'next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { SSRConfig, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { useRef, useState, useEffect, useMemo } from 'react';

const LoginPage: NextPage = () => {
  const { logEvent } = useAnalytics();
  const signInWithToken = useAuthMethod(AuthMethod.Token);
  const [isSigningInWithToken, setIsSigningInWithToken] = useState(false);
  const [approvedToRedirectToken, setApprovedToRedirect] = useApprovedToRedirect((state) => [
    state[RedirectKeys.Token],
    state.setApprovedToRedirect,
  ]);
  const router = useRouter();
  const { isRedirecting } = useRedirectOnAuthentication({
    to: router.query,
  });
  const { t } = useTranslation('auth');
  const { t: errorsT } = useTranslation('errors');
  const toast = useToast();
  const token = useRef(router.query.token?.toString() || router.query.t?.toString());
  const blockedRedirectExceptions = useMemo(
    () => [
      `${process.env.NEXT_PUBLIC_WWW_URL}/pricing`,
      `https://www.hihello.me/pricing`,
      `https://www.hihello.com/pricing`,
    ],
    [],
  );

  useEffect(() => {
    if (!token.current) {
      if (!approvedToRedirectToken) {
        setApprovedToRedirect(RedirectKeys.Token, true);
      }
      return;
    }

    const tokenAuth = async () => {
      if (isSigningInWithToken || !token.current) {
        return;
      }
      setIsSigningInWithToken(true);
      try {
        await signInWithToken(token.current);
      } catch (error) {
        token.current = undefined;
        setIsSigningInWithToken(false);
        toast({
          description: errorsT(error.message) || errorsT('auth/invalid-custom-token'),
          status: 'error',
        });
      }
    };

    tokenAuth();

    // exception here to allow people to proceed to the pricing page (logged out) if token expired
    if (
      router.query.next &&
      blockedRedirectExceptions.some((value) => router.query.next?.toString().includes(value))
    ) {
      router.push(new URL(router.query.next.toString()));
    }
  }, [
    approvedToRedirectToken,
    blockedRedirectExceptions,
    errorsT,
    setApprovedToRedirect,
    toast,
    router,
    signInWithToken,
    token,
    isSigningInWithToken,
  ]);

  useEffect(() => {
    if (router.query.error) {
      toast({
        description: errorsT(router.query.error as any) || errorsT('auth/unknown-error'),
        status: 'error',
      });
    }
  }, [errorsT, router.query.error, toast]);

  useEffect(() => {
    logEvent({
      event: AnalyticsEvent.AUTH_LOGIN_PAGE_VIEW,
      payload: {
        'page type': router.query.source?.toString() === 'claim' ? 'card claim' : 'standard',
      },
    });
  }, [logEvent, router.query.source]);

  return (
    <AuthContainer>
      <NextSeo description={t('pages.login.seo.description')} title={t('pages.login.seo.title')} />
      <Stack spacing="8">
        <Stack align="center" justify="center" spacing="6">
          <Logo />
          <Heading size="xs">
            {isRedirecting ? t('pages.login.headerRedirecting') : t('pages.login.header')}
          </Heading>
        </Stack>
        {isRedirecting || isSigningInWithToken ? (
          <Stack align="center" p="10">
            <Spinner color="brand.500" size="lg" />
          </Stack>
        ) : (
          <>
            <Flex justify="center">
              <Text color="muted" fontSize="sm">
                {t('pages.login.noAccount')}
                &nbsp;
                <NextLink href={{ pathname: '/signup', query: router.query }}>
                  <Link as="span" fontSize="sm">
                    {t('pages.login.signup')}
                  </Link>
                </NextLink>
              </Text>
            </Flex>
            <Stack spacing="6">
              <Login />
            </Stack>
            <Stack align="center" spacing="0.5">
              <Text color="muted" fontSize="sm">
                {t('pages.login.troubleLoggingInQuestion')}
              </Text>
              <Link href="mailto:support@hihello.com">
                <Text as="span" fontSize="sm">
                  {t('pages.login.contactUs')}
                </Text>
              </Link>
            </Stack>
            <Text color="subtle" fontSize="xs" textAlign="center">
              {t('pages.login.agreement')}
            </Text>
          </>
        )}
      </Stack>
    </AuthContainer>
  );
};

export const getServerSideProps: GetServerSideProps<SSRConfig> = async ({ locale = 'en' }) => {
  const translations = await serverSideTranslations(locale, ['common', 'auth', 'errors']);

  return {
    props: translations,
  };
};

export default LoginPage;
