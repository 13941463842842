import { Icon, useColorModeValue, useToken } from '@chakra-ui/react';
import { IconHiHello } from '@hihello/ui';

export const Logo = () => {
  const [brandColor] = useToken('colors', ['brand.500']);

  return (
    <Icon as={IconHiHello} color={useColorModeValue(brandColor, 'white')} h="14" w="10.375rem" />
  );
};
